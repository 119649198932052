import React, { Component } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"
import Layout from "../components/Layout"
import Container from "../components/Container"
import { NavLink } from "../components/Links"
import PostDisplay from "../components/Post"
import { Card, CardContainer } from "../components/CardGrid/CardGrid"

const CardContainerList = styled(CardContainer)`
  list-style-type: none;
`

class PostTemplate extends Component {
  render() {
    const post = this.props.data.post
    const related = this.props.data.related

    return (
      <Layout>
        <PostDisplay
          title={post.title}
          edition={
            post.categories[0] && post.categories[0].acf
              ? post.categories[0].acf.edition_display
              : "Miscellaneous"
          }
          editionSlug={
            post.categories[0] && `/${post.categories[0].slug}/editorial`
          }
          content={post.content}
          date={post.date}
          cover={post.cover && post.cover.localFile}
          slug={post.fields.slug}
          tags={post.tags}
        />

        <Container as="section">
          <h3>Related Entries</h3>
          <CardContainerList>
            {related.edges.map(
              ({
                node: {
                  title,
                  excerpt,
                  fields: { slug },
                },
              }) => (
                <Card key={slug} as="li">
                  <h3>
                    <NavLink to={slug}>{title}</NavLink>
                  </h3>
                  <div dangerouslySetInnerHTML={{ __html: excerpt }} />
                </Card>
              )
            )}
          </CardContainerList>
        </Container>
      </Layout>
    )
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!, $category: String!) {
    post: wordpressPost(id: { eq: $id }) {
      ...PostDetails
    }

    related: allWordpressPost(
      filter: {
        id: { ne: $id }
        categories: { elemMatch: { slug: { eq: $category } } }
      }
    ) {
      edges {
        node {
          slug
          title
          excerpt
          fields {
            slug
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        subtitle
      }
    }
  }
`
