import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Article from "../Article"
import Container from "../Container"

const PostDisplay = ({
  title,
  edition,
  editionSlug,
  content,
  date,
  cover,
  slug,
  tags,
}) => (
  <Container as="section">
    <header>
      <em>
        <Link to={editionSlug}>Edition {edition}</Link>
      </em>
      <h2>
        <Link to={slug} style={{ textDecoration: "none" }}>
          {title}
        </Link>
      </h2>
      {cover && <Img fluid={cover.childImageSharp.fluid} />}
    </header>
    <Article dangerouslySetInnerHTML={{ __html: content }} />
    <hr />
    <aside>
      <span title={`Published on ${new Date(date)}`}>
        {new Date(date).getMonth()} / {new Date(date).getFullYear()}
      </span>
      &emsp;—&emsp;
      {tags && tags.map(({ name, slug }) => <em key={slug}>{name}</em>)}
    </aside>
  </Container>
)

export default PostDisplay

export const query = graphql`
  fragment PostDetails on wordpress__POST {
    content
    date
    title
    fields {
      slug
    }
    cover: featured_media {
      localFile {
        childImageSharp {
          fluid(maxWidth: 1440, maxHeight: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    categories {
      name
      slug
      acf {
        edition
        edition_display
      }
    }
    tags {
      name
      slug
    }
    acf {
      quote
      cite
      is_editorial
    }
  }
`
